
.ship {
    background-image: url("../public/images/ship.png");
    background-position: left bottom;
    background-repeat: no-repeat;
    background-size: inherit;
    padding-top: 3rem;
}

.move:hover {
    transform: translateX(10px);
}


@media only screen and (min-width: 800px) {
    .foot-1 {
        width: 25%;
    }
}

.show{
    transition: transform 0.3s linear 0s, -webkit-transform 0.3s linear 0s;
}

@media only screen and (min-width: 800px){
    .foot-head {
        font-size: 25px;
        padding-bottom: 1.5rem;
    }
}

@media only screen and (max-width: 800px){
    .foot-head {
        font-size: 25px;
        padding-bottom: 0.5rem;
    }
}

