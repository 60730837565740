body {
  margin: 0;
  font-family: 'Rubik', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@media only screen and (max-width: 767px) {
  img.papertopimg{
    width: 320px;
  }
  .dnone-phonescreen{
    display: none;
  }
  .mx-5-for-ls{
    margin-inline: 0.5rem;
  }
  .supply-form-inner-col{
    width: 85% !important;
  }
}

.heade-in-live-price-page .headerglobal{
  background: #000 !important;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1290px) {
  img.papertopimg{
    width: 300px;
  }
}
.header-login-li .login-button-main{
  background-color: #ff8c00;
  padding-inline: 15px;
}
.footer-login-li .login-button-main{
  background-color: transparent;
  color: black;
  border: none !important;
  max-width: 55px;
}
button.login-button-main{
  background-color: #ff8c00;
}

.fin_part_img{
  width: 10%;
  background-color: white;
  padding: 10px;
}