@media only screen and (min-width: 800px) {
  .first-sec {
    width: 40% !important;
  }

  .head-image-pad {
    padding-top: 4.5rem !important;
  }

  .cross {
    left: 95%;
    top: 10px;
    cursor: pointer;
  }

  .how {
    padding: 2rem 3rem 6rem 3rem !important;
  }

  .txt-pad {
    width: 50% !important;
  }

  .how-ht {
    height: 30rem;
  }

  .digital-b {
    font-size: 35px !important;
  }

  .digital-br {
    font-size: 39px !important;
    color: #ff8c00 !important;
  }

  .stock-iso {
    position: absolute !important;
    transform: translateX(180deg) !important;
    left: -17rem !important;
  }
}

@media only screen and (min-width: 1100px) {
  .globe-sec {
    top: -250px;
    z-index: 5;
  }

  .credit-txt{
    width: 50%;
  }

  .credit-form{
    width: 75%;
  }

  .credit-val {
    left: 50%;
    top: -50px;
    position: absolute;
    width: 50%;
  }

  .top-sup {
    margin-top: -50px;
  }

  .details {
    font-size: 60px;
  }

  .market-top {
    padding-top: 6rem;
    padding-bottom: 9rem;
  }

  .head-pad {
    padding-top: 8rem !important;
  }


  .min-small {
    width: unset;
  }

  .pad-but {
    padding-bottom: 10rem;
  }

}

@media only screen and (max-width: 700px) {
  .top-swipe {
    order: 0;
  }

  .credit-val {
    width: 100%;
  }

  .top-sup {
    width: 100%;
  }

  .pad-mar {
    padding: 0 !important;
  }

  .market {
    width: 80%;
  }


  .mobile-size {
    width: 100%;
  }

  .blw-swipe {
    order: 1;
  }

  .cross {
    left: 86%;
    top: 10px;
    cursor: pointer;
  }
}

@media only screen and (min-width: 700px) and (max-width: 800px) {
  .cross {
    left: 90%;
    top: 10px;
    cursor: pointer;
  }
}



@media only screen and (max-width: 1100px) {

  .just {
    justify-content: space-around;
  }

  .details {
    font-size: 50px;
  }

  .market-top {
    padding-top: 6rem;
    padding-bottom: 7rem;
  }


  .market-text {
    width: 80%;
  }


  .pad-but {
    padding-bottom: 6rem;
  }

}

@media only screen and (max-width: 1100px) {
  .min-small {
    width: 100%;
  }

  .globe-sec {
    top: -80px;
    z-index: 5;
  }

}

@media only screen and (min-width: 600px) and (max-width: 700px) {
  .small-dev {
    left: 22%;
  }
}

.small-dev {
  height: 16rem;
}


.stock-iso {
  position: absolute;
  transform: translateX(180deg);
  left: -17rem;
  top: -82px;
}

.digital-b {
  font-size: 22px;
}

.digital-br {
  font-size: 24px;
  color: #ff8c00;
}


#scroll-text {
  /* animation properties */
  -moz-transform: translateX(100%);
  -webkit-transform: translateX(100%);
  transform: translateX(100%);

  -moz-animation: my-animation 50s linear infinite;
  -webkit-animation: my-animation 50s linear infinite;
  animation: my-animation 50s linear infinite;
}

@keyframes my-animation {
  from {
    -moz-transform: translateX(100%);
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }

  to {
    -moz-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

.scroll-text {
  font-size: 25px;
  margin-left: 1rem;
  margin-right: 1rem;
}

.sec-two {
  font-size: 13px;
}

.sec-two-head {
  font-size: 24px;
}

.save-sec-head {
  font-size: 36px;
  margin-bottom: 2rem;
  line-height: 1.5;
  /* padding-left: 11rem; */
}

.save-sec-text {
  font-size: 14px;
}

.head-pad {
  padding-top: 2.5rem;
}

.head-image-pad {
  padding-top: 1.5rem;
}

.save-sec-pad {
  padding-top: 5rem;
}

.stock {
  top: -10.5rem;
  right: -18rem;
}

.digital {
  background-image: url("../public/images/pattern.png");
  background-size: cover;
  padding: 4rem 0;
  text-align: center;
  border-bottom: 2px solid #002366;
}





/* Imported Css  */


[class^=btn-] {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  height: 40px;
  max-width: 140px;
  border: 1px solid #ff8c00;
}

.nav-but {
  position: relative !important;
  display: inline !important;
  overflow: hidden !important;
  width: 100% !important;
  height: 30px !important;
  max-width: 80px ;
  border: 1px solid #ff8c00 !important;
}


@-webkit-keyframes criss-cross-left {
  0% {
    left: -20px;
  }

  50% {
    left: 50%;
    width: 20px;
    height: 20px;
  }

  100% {
    left: 50%;
    width: 375px;
    height: 375px;
  }
}

@keyframes criss-cross-left {
  0% {
    left: -20px;
  }

  50% {
    left: 50%;
    width: 20px;
    height: 20px;
  }

  100% {
    left: 50%;
    width: 375px;
    height: 375px;
  }
}

@-webkit-keyframes criss-cross-right {
  0% {
    right: -20px;
  }

  50% {
    right: 50%;
    width: 20px;
    height: 20px;
  }

  100% {
    right: 50%;
    width: 375px;
    height: 375px;
  }
}

@keyframes criss-cross-right {
  0% {
    right: -20px;
  }

  50% {
    right: 50%;
    width: 20px;
    height: 20px;
  }

  100% {
    right: 50%;
    width: 375px;
    height: 375px;
  }
}

.btn-11 {
  position: relative;
  color: white;
}

.btn-11:before,
.btn-11:after {
  position: absolute;
  top: 50%;
  content: "";
  width: 20px;
  height: 20px;
  background-color: white;
  border-radius: 50%;
  z-index: 2;
}

.btn-11:before {
  left: -20px;
  transform: translate(-50%, -50%);
}

.btn-11:after {
  right: -20px;
  transform: translate(50%, -50%);
}

.btn-11:hover {
  color: #ff8c00;
  border: 1px solid white;
}

.btn-11:hover:before {
  -webkit-animation: criss-cross-left 0.8s both;
  animation: criss-cross-left 0.8s both;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
}

.btn-11:hover:after {
  -webkit-animation: criss-cross-right 0.8s both;
  animation: criss-cross-right 0.8s both;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
}

/* End Import Css  */


.image-rot {
  transition: transform .7s ease-in-out;
}

.sec-box {
  color: white;
  background-color: #00001c;
  transition: background 1s;
}

.sec-box:hover {
  color: #00001c;
  background-color: white;
}

.rotate {
  transform: rotateY(180deg);
}


.iso {
  animation: MoveUpDown 4s linear infinite;
}


@keyframes MoveUpDown {

  0%,
  100% {
    transform: translateY(-40px);
  }

  50% {
    transform: translateY(40px);
  }
}

.button-last {
  background-color: #1c1c32;
}

.button-last:hover {
  background-color: black;
}


.gradient-box {
  border-image: linear-gradient(#FF8C00, #8EF8AC) 30;
  border-width: 5px;
  border-style: solid;
}


@media (min-width: 992px) {

  .modal-lg,
  .modal-xl {
    --bs-modal-width: 1200px !important;
  }
}



.reg-img {
  background-image: url("../public/images/ship1.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.modal-content {
  background-color: transparent !important;
}

.modal-backdrop.show {
  background-color: #252525 !important;
  opacity: 0.8 !important;
}

.table {
  --bs-table-bg: transparent !important;
  border: 0px solid rgb(0, 0, 28) !important;
}

rect {
  fill: #000 !important;
}

g text {
  /* fill: #00bfff !important; */
  fill: #ff8c00 !important;
}

.image {
  width: 100%;
  transform: translate(0%);
  overflow: hidden;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  border: 2px solid white;
  border-radius: 10px;
  cursor: pointer;
}


.image:hover {
  .card-date {
    opacity: 1 !important;
    text-decoration: underline;
    text-decoration-color: #FF8C00;
    text-underline-offset: 3px;
  }
}

li::marker {
  color: #FF8C00;
}

.image span {
  position: relative;
  left: 20px;
  z-index: 1;
}

.zoom-out::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: inherit;
  background-size: cover;
  transform-origin: center;
  transition: transform 0.4s ease-in-out;
}

.zoom-out::after {
  transform: scale(1.1);
}

.zoom-out:focus::after,
.zoom-out:hover::after {
  transform: scale(1);
}

.card-date {
  margin-top: 10px;
}

.card-date {
  opacity: 1;
  text-decoration: none;
  -webkit-transition: all .2s cubic-bezier(.4, 0, 1, 1) .2s;
  /* transition: all .2s cubic-bezier(.4, 0, 1, 1) .2s; */
}

@media only screen and (min-width: 1200px) {
  /* .image span {
    top: 100px;
  } */

  .image {
    height: 400px;
  }

  .market-text {
    width: 50%;
  }
}

/* .hovered-label {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: white;
  padding: 5px;
  border: 1px solid black;
} */


.onhov-underline{
  text-decoration: none !important;
}

/* .onhov-underline:hover{
  text-decoration: none !important;
} */